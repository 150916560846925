/* body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #50378f 0%, #2a174f 100%);
    color: #fff;
    min-height: 90vh;
} */

.about {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}

.bio {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background: linear-gradient(45deg, #ff6b6b 0%, #7e5bef 100%);
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    text-align: center;
}

h1 {
    color: #fff;
    font-size: 3em;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
    color: #eee;
    line-height: 1.6;
}

.bio:hover {
    transform: scale(1.05);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Media Query for responsiveness */
@media (max-width: 600px) {
    .bio {
        margin: 20px;
    }
}