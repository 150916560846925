@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&display=swap');

.App {
  text-align: center;
  background-color: #191919;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

}
/* 
.About{
  overflow: scroll;
  height: 100vw;
} */

.parent-body{
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.content{
  flex: 1;
  overflow-y:auto;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.titlebar {
  text-align: center;
  background-color: #181818;
  height: 25px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.54);
  color: white;
  position: sticky;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.titlebar-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

}

.titlebar-button{
  /* background-color: transparent; */
  border: none;
  color: white;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
  /* make round */
  border-radius: 50%;
  padding: 6px;
  /* margin-top: 2px; */
  margin-right: 10px;
}

#close-button{
  background-color: #cd3e4a;
}

#minimize-button{
  background-color: rgb(31, 245, 38);
}

.titlebar-right{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 15px;
}





.topnav{
  
  background-color: #292929;
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  border:1px solid black;
  white-space: nowrap;
 
} 



.topnav-link{
  text-decoration: none;
  color: white;
  padding-top:8px;
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: #232222;
  
}

.titlebar p {
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: small;
  vertical-align: center;
  
}
.sidebar {
  display: flex;
  flex-direction: column;
  float: left;
  flex-wrap: wrap;
  background-color: #2f2f2f;
  min-height: 98vh;
  position:relative;
  height: 100%;
}

.link{
  text-decoration: none;

}

.menuitems{
  background-color: transparent ;
  padding-left: 16px;
  padding-right: 15px ;
  
  border: none;
}

.activemenuitems {
  background-color: transparent ;
  padding-left: 15px ;
  padding-right: 15px ;
  border: none;
  border-left: white solid 1px;
}

.menu-items:hover{
  background-color: none ;
}


.icons{
  font-size: 30px;
  color: #898989;
  border: none;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.icons:hover{
  color:rgb(255, 255, 255);
}

.active-icons{
  border-left: white solid 1;
  color: white;
}

.nav-explorer{
  display: flex;
  flex-direction: row;
}

.explorer{
  background-color: #202020;
  width: 15rem;
  font-family: 'Montserrat';
  color: rgb(224, 224, 224);
  /* padding-left: 15px; */
  text-align: left;
}

.explorer-p{
  padding-left: 15px;
  font-size: 13.5px;
}

.portfolio{
  /* font-weight:1000; */
  font-weight: bold;
}
.exp:hover {
  background-color: #313131;
}
.exp-link{
  text-decoration: none;
  color: rgb(222, 222, 222);
  font-size: 14px;
  font-weight: 600;
}

.js-icon{
  color: yellow;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

.py-icon {
  color: #4B8BBE;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

.rb-icon {
  /* ruby color */
  color: #CC342D;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}
.react-icon {
  /* ruby color */
  color: cyan;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

#link{
  margin-top:2px;
  margin-bottom: 2px;
  padding-top: 3px;
  padding-bottom: 3px;

}

.color{
  color:#3597e3
}

.explorer-links{
  border-left: rgb(150, 150, 150) solid 1px;
  margin-left: 22px;
}

.chev-icon {
  color: rgb(255, 255, 255);
  font-size: 15px;
  vertical-align: bottom;
  cursor: pointer;
}

.active-tab{
  background-color: #181818;
  border-top: 2px solid white;
}

/* .bottombar{
  height: 30px;
  background-color: blue;
  justify-content: end;
} */



@media only screen and (max-width: 836px){
  .explorer{
    display: none;
  }

  .sidebar{
    display: none;
  }
}

.project-page h1{
  font-size: 60px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
  color: white;
}
.project-page h2 {
  font-size: 30px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 30px;
  color: white;
}

.project-container{
  /* margin: 100px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.project-card{
  background-color: #c91111;
  color: white;
  width: 300px;
  height: 250px;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0px 2px 8px 1px rgba(81, 65, 65, 0.54);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.project-card:hover{
  background-color: #b00f0f;
}

.project-card p{
  font-size: 17px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.project-card h2{
  font-size: 25px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.project-card button{
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 15px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 100px;
}

.project-card button:hover{
  background-color: white;
  color: #c91111;
}

.error{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: white;
  font-size: 20px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.error h1{
  font-size: 200px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 30px;
  color: white;
  /* add text border and give shadow */
  text-shadow: 0px 2px 8px 1px rgba(255, 255, 255, 0.54);
  /* -webkit-text-stroke: 1px rgb(255, 0, 0); */
}

.error img{
  width: 400px;
  height: auto;
  margin-bottom: 20px;
}

.blog-container{
  /* margin: 100px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.blog-card{
  background-color: #000000;
  color: white;
  width: 30vw;
  height: auto;
  border-radius: 5px;
  padding: 15px;
  margin: 30px;
  box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition : all 0.5s ease-in-out;
}


.blog-card:hover{
  background-color: #37314d;
}

.blog-card img{
  width: 25vw;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
}

.tag{
  background-color: #37314d;
  color: white;
  font-size: 15px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 2px;
  text-wrap: wrap;
  /* width: 100px; */
}

.reactions{
  
  margin-bottom: 10px;
  color: thistle;

}

.minutes-read{
  margin-bottom: 10px;
  color: thistle;
}

.blog-card h1{
  font-size: 30px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog-card p{
  font-size: 17px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-card a{
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
}

.blog-card a:hover{
  background-color: white;
  color: #000000;
}

@media only screen and (max-width: 700px){
  .blog-card{
    width: 80vw;
    margin: 10px;
  }
  .blog-card img{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
  }
}


.git-container{
  /* margin: 100px; */
  height: 70vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.photo-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90%; */
  color: white;

  font-size: 20px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 0px;
  padding-top: 20px;
  margin-bottom: 10px;
  overflow-y: none ;
}

.photo-grid{
  /* width: 90vw; */
  width: 60%;
  /* height: 90%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.photo-container{
    border: 1px solid rgb(107, 107, 107);
    width: auto;
    height: 300px;
    margin: 5px;
    gap: 0px;
    /* box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54); */
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.photo-grid img{
  height: 100%;
  width: auto;
}

.photo-container:hover{
  transform: scale(1.1);

}

@media only screen and (max-width: 700px){
  .photo-grid{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
  .photo-container{
    height: 150px;
    margin: 5px;
    gap: 0px;
    box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
}





