@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Lato:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&family=Source+Code+Pro&display=swap');

.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90vh;
}

.form{
  padding: 30px;
  border-radius: 30px;
  width: 40vw;
  display: flex;
  flex-direction: column;
  /* background: rgb(55, 119, 66);
  background: linear-gradient(86deg, rgba(55, 119, 66, 1) 0%, rgba(0, 62, 56, 0.9178265056022409) 56%, rgba(3, 67, 70, 1) 100%); */
}

.form label{
  color: rgb(208, 255, 21);
  font-family: 'Montserrat' ;
  font-size: 2vh;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}

.form input{
  font-size: 20px;
  border: none;
  height: 5vh;
  color: rgb(50, 142, 162);
  /* border-radius: 25px; */
  background-color: rgb(18, 14, 14);
}

.form textarea{
  height: 16vh;
  border: none;
  color: rgb(255, 255, 255);
  /* border-radius: 25px; */
  background-color: rgb(18, 14, 14);
}

.form h2{
  color: rgb(166, 166, 166);
  text-align: left;
}
.verify{
  font-family: 'lato';
  color: rgb(208, 255, 21);
}
.send{
  margin-top: 15px;
  cursor: pointer;
}

.contact-icon{
  font-size: 30px;
  color: rgb(216, 62, 62);
  margin: 2vw;
}

.git{
  color: grey;
}

.linkedin {
  color: rgb(72, 152, 250);
}

.mail{
  color:rgb(255, 84, 98);
}

.blog{
  color: green;
}

.icons{
  flex-direction: row;
}





@media only screen and (max-width: 836px) {

  .form {
    
    width: 70vw;
    display: flex;
    flex-direction: column;
    /* margin-left: -40px;
    margin-right: 20px; */
    /* background: rgb(55, 119, 66);
    background: linear-gradient(86deg, rgba(55, 119, 66, 1) 0%, rgba(0, 62, 56, 0.9178265056022409) 56%, rgba(3, 67, 70, 1) 100%); */
  }
  
  .form label {
    color: rgb(208, 255, 21);
    font-family: 'Montserrat';
    font-size: 2vh;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
  }
  
  .form input {
    font-size: 20px;
    border: none;
    height: 5vh;
    color: rgb(50, 142, 162);
    /* border-radius: 25px; */
    background-color: rgb(18, 14, 14);
  }
  
  .form textarea {
    height: 16vh;
    border: none;
    color: rgb(255, 255, 255);
    /* border-radius: 25px; */
    background-color: rgb(18, 14, 14);
  }
  
  /* .form h2 {
    color: rgb(166, 166, 166);
    text-align: left;
  } */
 
}

