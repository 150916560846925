@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Lato:wght@900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&family=Source+Code+Pro&display=swap');


.main-body{
    
    background-color: #0D1117;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Lato';
    /* min-height: 100vh; */
    overflow: scroll;
    min-height: 94vh;
}

.container{
    padding-top: 3%;
    margin-left: 10%;
    margin-right: 10%;display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    overflow: scroll;

}

.dp-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; 
}

.dp{
    /* width: 200px; */
    height: 18vw;
    width: 18vw;
    overflow: hidden;
    border-radius: 50%;
    border: 1.5px solid rgb(56, 56, 56);
    justify-content: center;
    align-items: center;

    transition: all 0.5s ease-in-out;}

.dp-image{
    /* width: 400px; */
    height: 20vw;
    margin: 5px 0 0 0; 

        transition: all 0.5s ease-in-out;
    
}

@media only screen and (max-width: 768px) {
    .dp{
        height: 50vw;
        width: 50vw;
    transition: all 0.5s ease-in-out;
    /* animation: ease 2s linear infinite;
     */
    }

    .dp-image{
        height: 52vw;
    /* animation: ease 2s linear infinite; */
    transition: all 0.5s ease-in-out;
    }
}

.username{
    text-align: left;
}

.username h2{
    font-weight: bolder;
    color: #c1d1d9;
    font-size: 30px;
    margin-bottom: 0;
}

.username p{
    margin-top: 0;
    font-size: 26px;
    color: #8b949e;
}

.follow-button{
    background-color: rgba(200,200,200, .2);
    border: .3px solid rgb(148, 147, 147);
    color: #c1d1d9;
    font-size: 15px;
    padding: 7px;
    font-weight: bolder;
    border-radius: 7px;
    cursor: pointer;
}

.follow-button:hover{
    border: .7px solid rgb(221, 221, 221);
    background-color: rgba(209, 209, 209, 0.3);
}

.clock{
    vertical-align: bottom;
    margin-right: 5px;
    color: rgb(147, 147, 147);   
}

.repo-section{
    overflow: scroll;
    width: 45vw;
}

.repo{
    display: flex;
    flex-direction: column;
    text-align: left;
    
    border-top: .1px solid #3d4247;
    border-bottom: .1px solid #3d4247;
    justify-content: space-between;
}

.repo p{
    columns: #c1d1d9;
    margin-bottom: 20px;
}

.repo a{
    margin-top: 20px;
    text-decoration: none;
    font-size: 150%;
    font-weight: bolder;
    color: #58a6ff;
}

.Java{
    background-color: orange;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Javascript{
    background-color: yellow;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Ruby{
    background-color: #E0115F;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Cpp{
    background-color: blue;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}


/* @media only screen and (max-width: 768px) {
    .dp-name{
        display: flex;
        flex-direction: row;
    }

    .username h2 {
        margin-left: 10px;
        font-size: 20px;
        
    }
    
    .username p {
        margin-left: 10px;
        font-size: 15px;
    }

    .repo-section{
        width: 100%;
    }
} */