@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Lato:wght@900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&family=Source+Code+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@300&family=Encode+Sans:wght@300&family=Lato:wght@300&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #191919;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

}
/* 
.About{
  overflow: scroll;
  height: 100vw;
} */

.parent-body{
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.content{
  flex: 1 1;
  overflow-y:auto;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.titlebar {
  text-align: center;
  background-color: #181818;
  height: 25px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.54);
  color: white;
  position: -webkit-sticky;
  position: sticky;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.titlebar-left{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

}

.titlebar-button{
  /* background-color: transparent; */
  border: none;
  color: white;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
  /* make round */
  border-radius: 50%;
  padding: 6px;
  /* margin-top: 2px; */
  margin-right: 10px;
}

#close-button{
  background-color: #cd3e4a;
}

#minimize-button{
  background-color: rgb(31, 245, 38);
}

.titlebar-right{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 15px;
}





.topnav{
  
  background-color: #292929;
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  border:1px solid black;
  white-space: nowrap;
 
} 



.topnav-link{
  text-decoration: none;
  color: white;
  padding-top:8px;
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: #232222;
  
}

.titlebar p {
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: small;
  vertical-align: center;
  
}
.sidebar {
  display: flex;
  flex-direction: column;
  float: left;
  flex-wrap: wrap;
  background-color: #2f2f2f;
  min-height: 98vh;
  position:relative;
  height: 100%;
}

.link{
  text-decoration: none;

}

.menuitems{
  background-color: transparent ;
  padding-left: 16px;
  padding-right: 15px ;
  
  border: none;
}

.activemenuitems {
  background-color: transparent ;
  padding-left: 15px ;
  padding-right: 15px ;
  border: none;
  border-left: white solid 1px;
}

.menu-items:hover{
  background-color: none ;
}


.icons{
  font-size: 30px;
  color: #898989;
  border: none;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.icons:hover{
  color:rgb(255, 255, 255);
}

.active-icons{
  border-left: white solid 1;
  color: white;
}

.nav-explorer{
  display: flex;
  flex-direction: row;
}

.explorer{
  background-color: #202020;
  width: 15rem;
  font-family: 'Montserrat';
  color: rgb(224, 224, 224);
  /* padding-left: 15px; */
  text-align: left;
}

.explorer-p{
  padding-left: 15px;
  font-size: 13.5px;
}

.portfolio{
  /* font-weight:1000; */
  font-weight: bold;
}
.exp:hover {
  background-color: #313131;
}
.exp-link{
  text-decoration: none;
  color: rgb(222, 222, 222);
  font-size: 14px;
  font-weight: 600;
}

.js-icon{
  color: yellow;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

.py-icon {
  color: #4B8BBE;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

.rb-icon {
  /* ruby color */
  color: #CC342D;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}
.react-icon {
  /* ruby color */
  color: cyan;
  margin-right: 7px;
  font-size: 18px;
  margin-left: 5px;
  vertical-align: bottom;
}

#link{
  margin-top:2px;
  margin-bottom: 2px;
  padding-top: 3px;
  padding-bottom: 3px;

}

.color{
  color:#3597e3
}

.explorer-links{
  border-left: rgb(150, 150, 150) solid 1px;
  margin-left: 22px;
}

.chev-icon {
  color: rgb(255, 255, 255);
  font-size: 15px;
  vertical-align: bottom;
  cursor: pointer;
}

.active-tab{
  background-color: #181818;
  border-top: 2px solid white;
}

/* .bottombar{
  height: 30px;
  background-color: blue;
  justify-content: end;
} */



@media only screen and (max-width: 836px){
  .explorer{
    display: none;
  }

  .sidebar{
    display: none;
  }
}

.project-page h1{
  font-size: 60px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
  color: white;
}
.project-page h2 {
  font-size: 30px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 30px;
  color: white;
}

.project-container{
  /* margin: 100px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.project-card{
  background-color: #c91111;
  color: white;
  width: 300px;
  height: 250px;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0px 2px 8px 1px rgba(81, 65, 65, 0.54);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.project-card:hover{
  background-color: #b00f0f;
}

.project-card p{
  font-size: 17px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.project-card h2{
  font-size: 25px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.project-card button{
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 15px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 100px;
}

.project-card button:hover{
  background-color: white;
  color: #c91111;
}

.error{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: white;
  font-size: 20px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.error h1{
  font-size: 200px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 30px;
  color: white;
  /* add text border and give shadow */
  text-shadow: 0px 2px 8px 1px rgba(255, 255, 255, 0.54);
  /* -webkit-text-stroke: 1px rgb(255, 0, 0); */
}

.error img{
  width: 400px;
  height: auto;
  margin-bottom: 20px;
}

.blog-container{
  /* margin: 100px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.blog-card{
  background-color: #000000;
  color: white;
  width: 30vw;
  height: auto;
  border-radius: 5px;
  padding: 15px;
  margin: 30px;
  box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition : all 0.5s ease-in-out;
}


.blog-card:hover{
  background-color: #37314d;
}

.blog-card img{
  width: 25vw;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
}

.tag{
  background-color: #37314d;
  color: white;
  font-size: 15px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 2px;
  text-wrap: wrap;
  /* width: 100px; */
}

.reactions{
  
  margin-bottom: 10px;
  color: thistle;

}

.minutes-read{
  margin-bottom: 10px;
  color: thistle;
}

.blog-card h1{
  font-size: 30px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blog-card p{
  font-size: 17px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-card a{
  text-decoration: none;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
}

.blog-card a:hover{
  background-color: white;
  color: #000000;
}

@media only screen and (max-width: 700px){
  .blog-card{
    width: 80vw;
    margin: 10px;
  }
  .blog-card img{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
  }
}


.git-container{
  /* margin: 100px; */
  height: 70vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.photo-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 90%; */
  color: white;

  font-size: 20px;
  font-family: 'Encode Sans SC', sans-serif;
  font-weight: 600;
  margin-top: 0px;
  padding-top: 20px;
  margin-bottom: 10px;
  overflow-y: none ;
}

.photo-grid{
  /* width: 90vw; */
  width: 60%;
  /* height: 90%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.photo-container{
    border: 1px solid rgb(107, 107, 107);
    width: auto;
    height: 300px;
    margin: 5px;
    grid-gap: 0px;
    gap: 0px;
    /* box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54); */
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.photo-grid img{
  height: 100%;
  width: auto;
}

.photo-container:hover{
  transform: scale(1.1);

}

@media only screen and (max-width: 700px){
  .photo-grid{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
  .photo-container{
    height: 150px;
    margin: 5px;
    grid-gap: 0px;
    gap: 0px;
    box-shadow: 0px 2px 8px 1px rgba(15, 15, 15, 0.54);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
}






.main-body{
    
    background-color: #0D1117;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Lato';
    /* min-height: 100vh; */
    overflow: scroll;
    min-height: 94vh;
}

.container{
    padding-top: 3%;
    margin-left: 10%;
    margin-right: 10%;display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    overflow: scroll;

}

.dp-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; 
}

.dp{
    /* width: 200px; */
    height: 18vw;
    width: 18vw;
    overflow: hidden;
    border-radius: 50%;
    border: 1.5px solid rgb(56, 56, 56);
    justify-content: center;
    align-items: center;

    transition: all 0.5s ease-in-out;}

.dp-image{
    /* width: 400px; */
    height: 20vw;
    margin: 5px 0 0 0; 

        transition: all 0.5s ease-in-out;
    
}

@media only screen and (max-width: 768px) {
    .dp{
        height: 50vw;
        width: 50vw;
    transition: all 0.5s ease-in-out;
    /* animation: ease 2s linear infinite;
     */
    }

    .dp-image{
        height: 52vw;
    /* animation: ease 2s linear infinite; */
    transition: all 0.5s ease-in-out;
    }
}

.username{
    text-align: left;
}

.username h2{
    font-weight: bolder;
    color: #c1d1d9;
    font-size: 30px;
    margin-bottom: 0;
}

.username p{
    margin-top: 0;
    font-size: 26px;
    color: #8b949e;
}

.follow-button{
    background-color: rgba(200,200,200, .2);
    border: .3px solid rgb(148, 147, 147);
    color: #c1d1d9;
    font-size: 15px;
    padding: 7px;
    font-weight: bolder;
    border-radius: 7px;
    cursor: pointer;
}

.follow-button:hover{
    border: .7px solid rgb(221, 221, 221);
    background-color: rgba(209, 209, 209, 0.3);
}

.clock{
    vertical-align: bottom;
    margin-right: 5px;
    color: rgb(147, 147, 147);   
}

.repo-section{
    overflow: scroll;
    width: 45vw;
}

.repo{
    display: flex;
    flex-direction: column;
    text-align: left;
    
    border-top: .1px solid #3d4247;
    border-bottom: .1px solid #3d4247;
    justify-content: space-between;
}

.repo p{
    columns: #c1d1d9;
    margin-bottom: 20px;
}

.repo a{
    margin-top: 20px;
    text-decoration: none;
    font-size: 150%;
    font-weight: bolder;
    color: #58a6ff;
}

.Java{
    background-color: orange;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Javascript{
    background-color: yellow;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Ruby{
    background-color: #E0115F;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.Cpp{
    background-color: blue;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}


/* @media only screen and (max-width: 768px) {
    .dp-name{
        display: flex;
        flex-direction: row;
    }

    .username h2 {
        margin-left: 10px;
        font-size: 20px;
        
    }
    
    .username p {
        margin-left: 10px;
        font-size: 15px;
    }

    .repo-section{
        width: 100%;
    }
} */
.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90vh;
}

.form{
  padding: 30px;
  border-radius: 30px;
  width: 40vw;
  display: flex;
  flex-direction: column;
  /* background: rgb(55, 119, 66);
  background: linear-gradient(86deg, rgba(55, 119, 66, 1) 0%, rgba(0, 62, 56, 0.9178265056022409) 56%, rgba(3, 67, 70, 1) 100%); */
}

.form label{
  color: rgb(208, 255, 21);
  font-family: 'Montserrat' ;
  font-size: 2vh;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}

.form input{
  font-size: 20px;
  border: none;
  height: 5vh;
  color: rgb(50, 142, 162);
  /* border-radius: 25px; */
  background-color: rgb(18, 14, 14);
}

.form textarea{
  height: 16vh;
  border: none;
  color: rgb(255, 255, 255);
  /* border-radius: 25px; */
  background-color: rgb(18, 14, 14);
}

.form h2{
  color: rgb(166, 166, 166);
  text-align: left;
}
.verify{
  font-family: 'lato';
  color: rgb(208, 255, 21);
}
.send{
  margin-top: 15px;
  cursor: pointer;
}

.contact-icon{
  font-size: 30px;
  color: rgb(216, 62, 62);
  margin: 2vw;
}

.git{
  color: grey;
}

.linkedin {
  color: rgb(72, 152, 250);
}

.mail{
  color:rgb(255, 84, 98);
}

.blog{
  color: green;
}

.icons{
  flex-direction: row;
}





@media only screen and (max-width: 836px) {

  .form {
    
    width: 70vw;
    display: flex;
    flex-direction: column;
    /* margin-left: -40px;
    margin-right: 20px; */
    /* background: rgb(55, 119, 66);
    background: linear-gradient(86deg, rgba(55, 119, 66, 1) 0%, rgba(0, 62, 56, 0.9178265056022409) 56%, rgba(3, 67, 70, 1) 100%); */
  }
  
  .form label {
    color: rgb(208, 255, 21);
    font-family: 'Montserrat';
    font-size: 2vh;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
  }
  
  .form input {
    font-size: 20px;
    border: none;
    height: 5vh;
    color: rgb(50, 142, 162);
    /* border-radius: 25px; */
    background-color: rgb(18, 14, 14);
  }
  
  .form textarea {
    height: 16vh;
    border: none;
    color: rgb(255, 255, 255);
    /* border-radius: 25px; */
    background-color: rgb(18, 14, 14);
  }
  
  /* .form h2 {
    color: rgb(166, 166, 166);
    text-align: left;
  } */
 
}


/* body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #50378f 0%, #2a174f 100%);
    color: #fff;
    min-height: 90vh;
} */

.about {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}

.bio {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background: linear-gradient(45deg, #ff6b6b 0%, #7e5bef 100%);
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    text-align: center;
}

h1 {
    color: #fff;
    font-size: 3em;
    margin-bottom: 15px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

p {
    color: #eee;
    line-height: 1.6;
}

.bio:hover {
    transform: scale(1.05);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Media Query for responsiveness */
@media (max-width: 600px) {
    .bio {
        margin: 20px;
    }
}
